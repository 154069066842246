<template>
  <div class="positionT0L0">
    <div class="app-form">
      <a-form-model ref="ruleForm" :model="form" :rules="rules" labelAlign="right" :label-col="labelCol"
        :wrapper-col="wrapperCol">
        <a-form-model-item ref="title" label="名称" prop="title">
          <a-input v-model="form.title" placeholder="请输入名称" style="width: 420px">
            <div slot="addonAfter">{{ form.title.length }}/100</div>
          </a-input>
        </a-form-model-item>
        <a-form-model-item ref="effectiveDate" label="生效日期" prop="effectiveDate">
          <a-date-picker v-model="form.effectiveDate" @change="dateTimeChange" style="width: 420px;" />
        </a-form-model-item>
        <a-form-model-item ref="fileUrl" label="视频上传" prop="fileUrl">
          <QiniuVideoAudio :oldFileName="form.fileUrl" :type="2" id="videoUpdate" @videoFn="videoFn"
            @changeOldFileName="changeOldFileName" />
          <div style="font-weight: 600;line-height: 15px">格式支持mp4，为保证音频加载与播放的流畅性，<br>建议上传大小不超过100M。</div>
          <span style="color: #f5222d" v-if="isVideoUrl">请上传视频</span>
        </a-form-model-item>
        <a-form-model-item>
          <div v-for="(item, index) in form.questionList" :key="index">
            <div class="box" style="margin-top: 50px">
              <span class="tm">题目{{ index + 1 }}</span>
              <a-input v-model="item.subject" placeholder="请输入题目名称" style="width: 420px" />
            </div>
            <div class="box" v-for="(item2, index2) in item.options" :key="index2">
              <span class="tm">{{ item2.key }}</span>
              <a-input v-model="item2.title" placeholder="请输入选项文案" style="width: 420px" />
            </div>
            <div class="box">
              <span class="tm">答案</span>
              <div style="margin-top: 45rpx">
                <a-radio-group v-model="item.answer" name="radioGroup">
                  <a-radio value="A">A</a-radio>
                  <a-radio value="B">B</a-radio>
                  <a-radio value="C">C</a-radio>
                </a-radio-group>
              </div>
            </div>
          </div>
          <a-button v-if="form.questionList.length < 3" type="primary" style="margin-left: 200px" @click="addClick">+
            增加</a-button>
        </a-form-model-item>
      </a-form-model>
    </div>

    <footer-tool-bar :collapsed="sideCollapsed">
      <a-button class="margin_right60" @click="$router.go(-1)">返回</a-button>
      <a-button type="primary" @click="keepClick" :loading="isDisabled">保存</a-button>
    </footer-tool-bar>
  </div>
</template>

<script>
import FooterToolBar from "@/components/FooterToolbar";
import { baseMixin } from "@/store/app-mixin";
import { saveGame, getGameInfo } from "@/request/api/appletsManage";
import QiniuVideoAudio from "@/components/file/QiniuVideoAudio.vue";
import moment from "moment";

export default {
  mixins: [baseMixin],
  components: {
    FooterToolBar,
    QiniuVideoAudio
  },
  data () {
    return {
      labelCol: { span: 2 },
      wrapperCol: { span: 12 },
      isDisabled: false,
      isVideoUrl: false,
      form: {
        title: '',
        effectiveDate: '',
        fileUrl: '',
        questionList: [
          {
            subject: '',
            answer: '',
            options: [
              {
                key: 'A',
                title: ''
              }, {
                key: 'B',
                title: ''
              }, {
                key: 'C',
                title: ''
              }
            ]
          }
        ]
      },
      rules: {
        title: [
          { required: true, message: "请输入视频名称", trigger: "blur" },
          { max: 100, message: "最多输入100个字符", trigger: "change" },
        ],
        effectiveDate: [
          { required: true, message: "请选择生效日期", trigger: "change" }
        ],
        fileUrl: [{ required: true, message: "", }],
      },
    };
  },
  created () {
    let id = this.$route.params.id
    if (id !== '0') {
      getGameInfo({
        id: id * 1
      }).then(({ code, data }) => {
        this.form = data
      });
    }
  },
  methods: {
    moment,
    dateTimeChange (val, dateString) {
      this.form.effectiveDate = dateString
    },
    // 视频函数
    videoFn (obj) {
      this.form.fileUrl = obj.fileUrl;
    },
    // 点击新增
    addClick () {
      let obj = {
        subject: '',
        answer: '',
        options: [
          {
            key: 'A',
            title: ''
          }, {
            key: 'B',
            title: ''
          }, {
            key: 'C',
            title: ''
          }
        ]
      }
      this.form.questionList.push(obj)
    },

    // 改变视频文件名
    changeOldFileName (oldFileName) { },

    keepClick () {
      console.log(this.form);
      const that = this;
      if (this.form.fileUrl) {
        this.isVideoUrl = false
      } else {
        this.isVideoUrl = true
        return false
      }
      that.isDisabled = true;
      setTimeout(() => {
        that.isDisabled = false;
      }, 3000);
      that.$refs.ruleForm.validate((valid) => {
        if (valid) {
          if (that.$route.params.id !== '0') that.form.id = that.$route.params.id * 1
          saveGame(that.form).then((res) => {
            if (res.code == 200) {
              that.$message.success("新增成功");
              that.$router.push('/appletsManage/classicsYdt')
            }
          });
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.box {
  display: flex;

  .tm {
    width: 50px;
    text-align: right;
    margin-right: 10px;
  }
}

.btn {
  text-align: right;
}
</style>
