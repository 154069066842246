<template>
  <div class="positionT0L0">
    <div class="app-form">
      <a-form-model ref="ruleForm" :model="form" :rules="rules" labelAlign="right" :label-col="labelCol"
        :wrapper-col="wrapperCol">
        <a-form-model-item ref="title" label="名称" prop="title">
          <a-input v-model="form.title" placeholder="请输入名称" style="width: 420px">
            <div slot="addonAfter">{{ form.title.length }}/100</div>
          </a-input>
        </a-form-model-item>
        <a-form-model-item ref="effectiveDate" label="生效日期" prop="effectiveDate">
          <a-date-picker v-model="form.effectiveDate" @change="dateTimeChange" style="width: 420px;" />
        </a-form-model-item>
        <a-form-model-item ref="fileUrl" label="视频上传" prop="fileUrl">
          <QiniuVideoAudio :oldFileName="form.fileUrl" :type="2" id="videoUpdate" @videoFn="videoFn"
            @changeOldFileName="changeOldFileName" />
          <div style="font-weight: 600;line-height: 15px">格式支持mp4，为保证音频加载与播放的流畅性，<br>建议上传大小不超过100M。</div>
          <span style="color: #f5222d" v-if="isVideoUrl">请上传视频</span>
        </a-form-model-item>
        <a-form-model-item ref="shareImageUrlArr" label="分享海报" prop="shareImageUrlArr">
          <UpdataImg @avatarfns="shareImageUrlFun" :imageUrlNum="1" :imgUrl="shareImageUrlArr" :urlSize="500"></UpdataImg>
          <span v-if="shareImageUrlShow" style="color: red;">请上传分享海报</span>
        </a-form-model-item>
        <a-form-model-item ref="shareImageQrArr" label="海报二维码" prop="shareImageQrArr">
          <UpdataImg @avatarfns="shareImageQrFun" :imageUrlNum="1" :imgUrl="shareImageQrArr" :urlSize="500"></UpdataImg>
          <span v-if="shareImageShow" style="color: red;">请上传分享海报</span>
        </a-form-model-item>
        <a-form-model-item>
          <div v-for="(item, index) in form.questionList" :key="index" style="margin-bottom: 30px;">
            <div style="margin-left: 20px;">
              <span class="tm">题目{{ index + 1 }}：</span>
              <a-radio-group v-model="item.questionType" @change="typeChange">
                <a-radio :value="1">选择题</a-radio>
                <a-radio :value="2">看图猜成语</a-radio>
              </a-radio-group>
            </div>
            <template v-if="item.questionType === 1">
              <div class="box">
                <span class="tm">标题</span>
                <a-input v-model="item.subject" placeholder="请输入标题名称" style="width: 420px" />
              </div>
              <div class="box" v-for="(item2, index2) in item.options" :key="index2">
                <span class="tm">{{ item2.key }}</span>
                <a-input v-model="item2.title" placeholder="请输入选项文案" style="width: 420px" />
              </div>
              <div class="box">
                <span class="tm">答案</span>
                <div>
                  <a-radio-group v-model="item.answer" name="radioGroup">
                    <a-radio value="A">A</a-radio>
                    <a-radio value="B">B</a-radio>
                    <a-radio value="C">C</a-radio>
                    <a-radio value="D">D</a-radio>
                  </a-radio-group>
                </div>
              </div>
            </template>
            <template v-else>
              <div class="box">
                <span class="tm">标题</span>
                <a-input v-model="item.subject" placeholder="请输入标题名称" style="width: 420px" />
              </div>
              <div @click="imgUrlFns(index)">
                <UpdataImg style="margin-left: 70px;" @avatarfns="imgUrlFn" :imageUrlNum="1" :imgUrl="item.imageUrl" :urlSize="500"></UpdataImg>
              </div>
              <a-alert message="词库内容请以'你,我,他'格式输入，标点符号请用英文逗号" type="error" style="margin-left: 62px;margin-top:20px;width: 420px"/>
              <div class="box" style="margin-left: 20px;">
                <span>词库：</span>
                <a-input v-model="item.dictionary" placeholder="请输入词库" style="width: 420px"></a-input>
              </div>
              <div class="box" style="margin-left: 20px;margin-top:20px">
                <span>答案：</span>
                <a-input v-model="item.answer" placeholder="请输入答案" style="width: 420px"></a-input>
              </div>
            </template>
          </div>
          <a-button v-if="form.questionList.length < 3" type="primary" style="margin-left: 200px" @click="addClick">+增加</a-button>
        </a-form-model-item>
      </a-form-model>
    </div>

    <footer-tool-bar :collapsed="sideCollapsed">
      <a-button class="margin_right60" @click="$router.go(-1)">返回</a-button>
      <a-button type="primary" @click="keepClick" :loading="isDisabled">保存</a-button>
    </footer-tool-bar>
  </div>
</template>

<script>
import FooterToolBar from "@/components/FooterToolbar";
import { baseMixin } from "@/store/app-mixin";
import { saveAnswer, getAnswerInfo } from "@/request/api/appletsManage";
import QiniuVideoAudio from "@/components/file/QiniuVideoAudio.vue";
import moment from "moment";
import UpdataImg from "@/components/file/updataImg";

export default {
  mixins: [baseMixin],
  components: {
    FooterToolBar,
    QiniuVideoAudio,
    UpdataImg
  },
  data () {
    return {
      labelCol: { span: 2 },
      wrapperCol: { span: 12 },
      isDisabled: false,
      isVideoUrl: false,
      shareImageUrlArr: [],
      shareImageUrlShow: false,
      shareImageQrArr: [],
      shareImageShow: false,
      form: {
        title: '',
        effectiveDate: '',
        fileUrl: '',
        shareImageUrl: '',
        shareImageQr: '',
        questionList: [
          {
            questionType:1,
            subject: '',
            answer: '',
            options: [
              {
                key: 'A',
                title: ''
              }, {
                key: 'B',
                title: ''
              }, {
                key: 'C',
                title: ''
              }, {
                key: 'D',
                title: ''
              }
            ],
            image: '',
            imageUrl: [],
            dictionary: '',
          }
        ],
      },
      numLength:0,
      rules: {
        title: [
          { required: true, message: "请输入视频名称", trigger: "blur" },
          { max: 100, message: "最多输入100个字符", trigger: "change" },
        ],
        effectiveDate: [
          { required: true, message: "请选择生效日期", trigger: "change" }
        ],
        fileUrl: [{ required: true, message: "", }],
        shareImageUrlArr: [{ required: true, message: "", }],
        shareImageQrArr: [{ required: true, message: "", }],
      },
    };
  },
  created () {
    let id = this.$route.params.id
    if (id !== '0') {
      getAnswerInfo({
        id: id * 1
      }).then(({ code, data }) => {
        let form = data
        this.shareImageUrlArr = data.shareImageUrl.split(',')
        this.shareImageQrArr = data.shareImageQr.split(',')
        for (let i = 0; i < data.questionList.length; i++){
          if (data.questionList[i].image) {
            form.questionList[i].imageUrl = data.questionList[i].image.split(',')
          } else {
            form.questionList[i].imageUrl = []
          }
        }
        this.form = form
      });
    }
  },
  methods: {
    moment,
    typeChange (val) {
      console.log(val.target.value)
    },
    imgUrlFns (index){
      this.numLength = index
    },
    // 上传回调
    imgUrlFn (data) {
      console.log(this.form.questionList)
    },

    dateTimeChange (val, dateString) {
      this.form.effectiveDate = dateString
    },
    // 视频函数
    videoFn (obj) {
      this.form.fileUrl = obj.fileUrl;
    },
    // 点击新增
    addClick () {
      let obj = {
        questionType:1,
        subject: '',
        answer: '',
        options: [
          {
            key: 'A',
            title: ''
          }, {
            key: 'B',
            title: ''
          }, {
            key: 'C',
            title: ''
          }, {
            key: 'D',
            title: ''
          }
        ],
        image: '',
        imageUrl: [],
        dictionary: '',
      }
      this.form.questionList.push(obj)
    },

    // 改变视频文件名
    changeOldFileName (oldFileName) { },

    shareImageUrlFun (val) {
      console.log(this.shareImageUrlArr)
    },

    shareImageQrFun (val) {
      console.log(this.shareImageQrArr)
    },

    keepClick () {
      console.log(this.form);
      const that = this;
      if (this.form.fileUrl) {
        this.isVideoUrl = false
      } else {
        this.isVideoUrl = true
        return false
      }
      if (this.shareImageUrlArr.length > 0) {
        this.form.shareImageUrl = this.shareImageUrlArr.toString()
        this.shareImageUrlShow = false
      } else {
        this.shareImageUrlShow = true
        return
      }

      if (this.shareImageQrArr.length > 0) {
        this.form.shareImageQr = this.shareImageQrArr.toString()
        this.shareImageShow = false
      } else {
        this.shareImageShow = true
        return
      }

      for (let i = 0; i < this.form.questionList.length; i++){
        this.form.questionList[i].image = this.form.questionList[i].imageUrl.toString()
      }
      
      that.isDisabled = true;
      setTimeout(() => {
        that.isDisabled = false;
      }, 3000);
      that.$refs.ruleForm.validate((valid) => {
        if (valid) {
          if (that.$route.params.id !== '0') that.form.id = that.$route.params.id * 1
          saveAnswer(that.form).then((res) => {
            if (res.code == 200) {
              that.$message.success("新增成功");
              that.$router.push('/appletsManage/mzYdt')
            }
          });
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.box {
  display: flex;

  .tm {
    width: 50px;
    text-align: right;
    margin-right: 10px;
  }
}

.btn {
  text-align: right;
}
</style>
